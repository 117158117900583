// This file is for global styles
// In this case is for:
// - Bulma framework
// - Font Awesome icons
@import "constants";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~bulma/bulma.sass";
@import '~react-datepicker/dist/react-datepicker-cssmodules.css';
@import "styles";
@import "animations";
@import "animate.scss";
@import "~react-toastify/dist/ReactToastify.css";
