/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/

$first_color: #d8560c;
$second_color: #f39531;
$third_color: #fffffe;
$fourth_color: #22324e;
$fifth_color: #054B62;
$sixth_color: #FB645D;
$seventh_color: #cbcbcb;
$eighth_color: #e3e3e3;
$nineth_color: #f9f9f9;
$tenth_color: #ffffff;
$eleventh_color: #c5c5c5;
$twelfth_color: #cbcbcb;
$text_color: #80848a;
$text_color_dark: #22324e;
$background_layout: #eeeff4;
$link_login: #36a0ce;

/*variables de bulma*/

$primary: $first_color;
$link: $second_color;
$info: #025B9D;
$success: #078469;
/*$warning: $fifth_color;*/
$danger: #9C1124;

/*Sizes*/
$sizes: 0, 4px, 8px, 10px, 12px, 16px, 20px, 24px, 28px, 32px, 36px, 40px, 44px, 48px, 52px, 56px, 60px, 64px, 68px, 72px, 76px, 80px;

/*Responsive %*/
$rFullHD: 1;
$rWideScreen: 0.95;
$rDesktop: 0.90;
$rTablet: 0.85;
$rMobileXL: 0.85;
$rMobileL: 0.85;
$rMobileM: 0.85;
$rMobileS: 0.85;

$rMobileXLM: 1;
$rMobileLM: 0.67;
$rMobileMM: 0.57;
$rMobileSM: 0.52;
