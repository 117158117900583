@import "./_constants.scss";

@mixin styling($responsive, $responsiveMenu) {
  html {
    background-color: #eeeff4;
    height: auto;
  }
  @font-face {
    font-family: 'Nexa Light';
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/Nexa Light.otf") format("opentype");
  }

  @font-face {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: bold;
    src: url("/fonts/Nexa Bold.otf") format("opentype");
  }

  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/Gotham Medium.otf") format("opentype");
  }

  body {
    font-family: 'Nexa Bold', Arial, sans-serif;
    font-size: calc(13px * #{$responsive});
    line-height: 1.42857143;
    color: #404040;
    background-color: $background_layout;
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  @font-face {
    font-family: 'Open Sans Cond Light';
    src: url('./fonts/OpenSans-CondLight.ttf');
  }

  @font-face {
    font-family: 'Open Sans Cond Bold';
    src: url('./fonts/OpenSans-CondBold.ttf');
  }

  @font-face {
    font-family: 'Open Sans Regular';
    src: url('./fonts/OpenSans-Regular.ttf');
  }

  button {
    font-family: "Open Sans", sans-serif;
  }

  h1 {
    font-family: "Nexa Bold", Arial, sans-serif;
  }
  h2 {
    font-family: "Nexa Bold", Arial, sans-serif;
    text-align: center;
    margin: calc(30px * #{$responsive}) 0 calc(30px * #{$responsive});
    font-size: calc(30px * #{$responsive});
    color: $second_color;
  }
  h3 {
    font-family: "Nexa Bold", Arial, sans-serif;
    text-align: center;
    margin: calc(10px * #{$responsive}) 0 calc(30px * #{$responsive});
    font-size: calc(27px * #{$responsive});
  }

  h4 {
    font-family: "Nexa Bold", Arial, sans-serif;
    text-align: left;
    font-size: calc(21px * #{$responsive});
    color: $text_color;
  }
  .text-success {
    color: $success;
  }
  /**
    Table
   */
  hr {
    margin: 0;

    &.dots {
      height: 2px;
      border-top: 2px dashed $seventh_color;
      margin: calc(5px * #{$responsive}) calc(10px * #{$responsive}) 0 calc(10px * #{$responsive}) calc(10px * #{$responsive});
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }

  .select-searchable {
    .select__menu {
      position: absolute;
      z-index: 99999999 !important;
    }
  }

  .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  .panel-table tr th:first-child, .panel-table tr td:first-child {
    padding-left: calc(20px * #{$responsive});
  }

  .panel-table tr th:last-child, .panel-table tr td:last-child {
    padding-right: calc(20px * #{$responsive});
  }

  th {
    text-align: left;
  }

  .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-bottom: 2px solid #ddd;
    font-size: calc(13px * #{$responsive});
    font-weight: 700;
    line-height: 1.42857;
    padding: calc(7px * #{$responsive}) calc(10px * #{$responsive});
    vertical-align: middle;
    height: 55px;
    @media only screen and (max-width: 600px) {
      font-size: calc(11px * #{$responsive}) calc(10px * #{$responsive});
    }
  }

  .table > tbody > tr > td {
    font-weight: 400;
  }

  /**
   Text
   */
  .button.is-small {
    padding: calc(#{$responsive} * 2px) calc(#{$responsive} * 10px);
    height: auto;
    border-radius: 29.5px;
    margin-top: 2px;
  }

  .text-verde {
    color: $fourth_color !important;
  }

  .text-white {
    color: $tenth_color !important;
  }
  .text-decoration-none {
    text-decoration: none;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: block;
    float: left;
    margin-right: calc(10px * #{$responsive});
  }

  .label-checkbox {
    color: $twelfth_color;
    font-size: calc(13px * #{$responsive});
    display: block;
  }

  div.field.is-horizontal {
    padding-left: 5%;
    padding-right: 5%;
  }

  button.margin-right-5 {
    margin-right: 5%;
  }
  .button {
    padding: 0 calc(25px * #{$responsive});
    border-radius: 29.5px;
    font-size: calc(17px * #{$responsive});
    height: 45px;

    &.is-custom-primary {
      background: $first_color;
      color: $tenth_color;
    }

    &.is-custom-default {
      background: transparent;
      border: 2px solid $second_color;
      color: $second_color;
    }

    &.is-custom-back {
      height: 45px;
      color: $text_color;
      border-radius: 29.5px;
    }
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .text-completed {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }

  i div.__react_component_tooltip {
    font-family: Arial, sans-serif !important;
  }
  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    background-color: $first_color;

  }
  .react-datepicker__month-text.react-datepicker__month--selected, .react-datepicker__month-text.react-datepicker__month--in-range {
    &:hover {
      background-color: $second_color;
    }
  }
  button.react-datepicker__close-icon {
    height: 27px;
    width: 27px;
    top: 5px;

    &::after {
      background-color: transparent;
      color: #cbcbcb;
      height: 27px;
      width: 27px;
      content: "\f057";
      font-family: 'Font Awesome 5 Free', sans-serif;
      font-size: 1.3rem;
    }
  }

  @media (min-width: 1408px) {
    .content-xl-center {
      max-width: 960px;
      margin: 0 auto;
      display: block;
    }
  }
  @each $size in $sizes {
    .mt-#{$size} {
      margin-top: calc(#{$size} * #{$responsive}) !important;
    }
    .ml-#{$size} {
      margin-left: calc(#{$size} * #{$responsive}) !important;
    }
    .mr-#{$size} {
      margin-right: calc(#{$size} * #{$responsive}) !important;
    }
    .mb-#{$size} {
      margin-bottom: calc(#{$size} * #{$responsive}) !important;
    }
    .m-#{$size} {
      margin: calc(#{$size} * #{$responsive}) !important;
    }
    .pt-#{$size} {
      padding-top: #{$size} !important;
    }
    .pl-#{$size} {
      padding-left: calc(#{$size} * #{$responsive}) !important;
    }
    .pr-#{$size} {
      padding-right: calc(#{$size} * #{$responsive}) !important;
    }
    .pb-#{$size} {
      padding-bottom: calc(#{$size} * #{$responsive}) !important;
    }
    .p-#{$size} {
      padding: calc(#{$size} * #{$responsive}) !important;
    }
  }

  .PhoneInputInput {
    height: 2.25em !important;
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;

    :focus {
      outline: none !important;
    }
  }
  .group-actions {
    display: flex;

    .button-switch {
      top: 2px;
    }

    .change-password {
      display: flex;
      position: relative;
      margin-left: 15px;
    }
  }
}

@import "./_responsive.scss";
