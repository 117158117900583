.form-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .field-label {
    width: 200% !important;
    text-align: center;
    .label {
      text-align: center !important;
    }
  }

  .input-extraction {
    width: 255%;
    margin-left: -15%;
    text-align: center !important;
  }

  .label-amount-debited {
    color: #363636;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .input-reason {
    width: 400%;
    margin-left: -80%;
    text-align: center !important;
  }

}

.success-text {
  text-align: center !important;
  font-weight: bold;
  font-size: calc(20px * 0.95);
  margin-bottom: 1%;
}
